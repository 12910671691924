import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import pinia from './store/index'
import VueUeditorWrap from 'vue-ueditor-wrap'

import '@/styles/index.scss'
import 'animate.css/animate.min.css'
import './setting.css'
//阿里图标库
import '@/assets/iconfont/iconfont.css'
import '@/permission'

createApp(App)
	.use(VueUeditorWrap)
	.use(router)
	.use(ElementPlus, { locale: zhCn })
	.use(pinia)
	.mount('#app')
