import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { getLoginRouterPath } from '@/config/setting'
/**
 * hidden: true                     如果设置为true，项目将不会显示在侧边栏中(默认为false)
 * alwaysShow: true                 如果设置为true，将始终显示根菜单
 *                                  如果没有设置alwaysShow，当item有多个子路由时，
 * 									它将变成嵌套模式，否则不显示根菜单
 * redirect: noRedirect             如果设置了noRedirect，则breadcrumb中不会有重定向
 * name:'router-name'               该名称由<keep-alive>使用(必须设置!!)
 * meta : {
    roles: ['admin','editor']       控制页面角色(可以设置多个角色)
    title: 'title'                  在侧边栏和面包屑中显示的名称(推荐设置)
    icon: 'svg-name'/'el-icon-x' 	图标显示在侧边栏中,*注意这里全部使用阿里图标库icon
    noCache: true                   如果设置为true，页面将不会被缓存(默认为false)
    affix: true                     如果设置为true，标签将被添加到标签视图中
    breadcrumb: false            	如果设置为false，项目将隐藏在breadcrumb中(默认为true)
    activeMenu: '/example/list'     如果设置了path，侧边栏将突出显示您设置的路径
  }
 */
declare module 'vue-router' {
	interface _RouteRecordBase {
		hidden?: boolean | string | number
		alwaysShow?: boolean
		fullPath?: string
	}
}

import Layout from '@/layout/index.vue'
// 基础路由
export const constantRoutes: Array<RouteRecordRaw> = [
	{
		path: '/redirect',
		component: Layout,
		hidden: true,
		children: [
			{
				path: '/redirect/:path*',
				component: () => import('@/views/redirect/index.vue'),
			},
		],
	},
	{
		path: '/404',
		component: () => import('@/views/404.vue'),
		hidden: true,
	},
	{
		path: '/302',
		redirect: '/302/index',
		component: Layout,
		hidden: true,
		children: [
			{
				path: 'index',
				name: '302',
				component: () => import('@/views/302.vue'),
			},
		],
	},
	{
		path: '/changePassword',
		redirect: '/changePassword/index',
		component: Layout,
		hidden: true,
		children: [
			{
				path: 'index',
				name: 'changePassword',
				meta: {
					title: '修改密码',
					noCache: true,
				},
				component: () =>
					import('@/views/rolepermission/changePassword/index.vue'),
			},
		],
	},
	// 首页路径和名称请不要修改
	{
		path: '/',
		redirect: '/dashboard',
		component: Layout,
		children: [
			{
				path: 'dashboard', //不要修改
				name: 'Dashboard', //不要修改
				component: () => import('@/views/dashboard/index.vue'),
			},
		],
	},
	{
		path: '/login',
		component: () => getLoginRouterPath(),
		hidden: true,
	},
]
/**
 * 动态路由
 */
export const asyncRoutes: Array<RouteRecordRaw> = [
	{
		path: '/rolepermission',
		component: Layout,
		redirect: '/rolepermission/accountmanage/index',
		meta: {
			title: '系统设置',
			noCache: true,
			icon: 'icon-xitong',
		},
		children: [
			{
				path: 'accountmanage/index',
				name: 'AccountManage',
				component: () =>
					import('@/views/rolepermission/accountmanage/index.vue'),
				meta: {
					title: '账号管理',
					noCache: true,
					roles: ['bffcfa41c6ea502ff551454ad7ca9542'],
					icon: 'icon-zhanghaoguanli',
				},
			},
			{
				path: 'rolemanage/index',
				name: 'RoleManage',
				component: () => import('@/views/rolepermission/rolemanage/index.vue'),
				meta: {
					title: '角色管理',
					noCache: true,
					roles: ['178d87b79220734ec6efc6e11ba605e2'],
					icon: 'icon-jiaoseguanli',
				},
			},
			{
				path: 'addrole/index',
				name: 'AddRole',
				hidden: true,
				component: () =>
					import('@/views/rolepermission/rolemanage/addrole/index.vue'),
				meta: {
					noCache: true,
					activeMenu: '/rolepermission/rolemanage/index',
					title: '添加角色',
					icon: 'icon-dingdanguanli',
					// roles: ['21e4867b68d134d5e77e84aee6616b7d'],
				},
			},
			{
				path: 'addaccount/index',
				name: 'AddAccount',
				hidden: true,
				component: () =>
					import('@/views/rolepermission/accountmanage/addaccount/index.vue'),
				meta: {
					noCache: true,
					activeMenu: '/rolepermission/accountmanage/index',
					title: '添加账号',
					// roles: ['086117566c2c4f08fc1520d03fdfb452'],
				},
			},
			// {
			// 	path: 'beilv/index',
			// 	name: 'beilv',
			// 	component: () => import('@/views/rolepermission/beilv/index.vue'),
			// 	meta: {
			// 		title: '价格倍率管理',
			// 		noCache: true,
			// 		icon: 'icon-xitongguanli',
			// 		roles: ['4f12dd5ece7ac19824d5b4de539fa8ae'],
			// 	},
			// },
			// {
			// 	path: 'integral/index',
			// 	name: 'Integral',
			// 	component: () => import('@/views/rolepermission/Integral/index.vue'),
			// 	meta: {
			// 		title: '积分规则管理',
			// 		noCache: true,
			// 		roles: ['a662c5d68e501df455e616f03950fd3a'],
			// 		icon: 'icon-jifen01',
			// 	},
			// },
		],
	},
	{
		path: '/carmanage',
		redirect: '/carmanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '车辆管理',
			roles: ['7c59e49670d442acfabb863064b9134f'],
		},
		children: [
			{
				path: 'index',
				name: 'CarManage',
				component: () => import('@/views/CarManage/index.vue'),
				meta: {
					noCache: false,
					title: '车辆管理',
					icon: 'icon-cheliang',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'caredit',
				name: 'CarEditManage',
				hidden: true,
				component: () => import('@/views/CarManage/edit.vue'),
				meta: {
					noCache: true,
					title: '车辆编辑',
					icon: 'icon-bianji',
					activeMenu: '/carmanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'cardetail',
				name: 'CarDetailManage',
				hidden: true,
				component: () => import('@/views/CarManage/detail.vue'),
				meta: {
					noCache: false,
					title: '车辆详情',
					icon: 'icon-dingdan',
					activeMenu: '/carmanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/intercitymanage',
		redirect: '/intercitymanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '跨市申请管理',
			roles: ['9fb817c40a7f7f903196d52e90d33b29'],
		},
		children: [
			{
				path: 'index',
				name: 'IntercityManage',
				component: () => import('@/views/IntercityManage/index.vue'),
				meta: {
					noCache: false,
					title: '跨市申请管理',
					icon: 'icon-24gl-city3',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'intercitydetail',
				name: 'IntercityDetailManage',
				hidden: true,
				component: () => import('@/views/IntercityManage/detail.vue'),
				meta: {
					noCache: false,
					title: '跨市申请详情',
					icon: 'icon-dingdan',
					activeMenu: '/intercitymanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/carrecordmanage',
		redirect: '/carrecordmanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '用车记录管理',
			roles: ['155941c69e52ffb088ae177b1054f92a'],
		},
		children: [
			{
				path: 'index',
				name: 'CarRecordManage',
				component: () => import('@/views/CarRecordManage/index.vue'),
				meta: {
					noCache: false,
					title: '用车记录管理',
					icon: 'icon-bofangjilu',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'carrecorddetail',
				name: 'CarRecordDetailManage',
				hidden: true,
				component: () => import('@/views/CarRecordManage/detail.vue'),
				meta: {
					noCache: false,
					title: '用车记录详情',
					icon: 'icon-dingdan',
					activeMenu: '/carrecordmanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/maintainmanage',
		redirect: '/maintainmanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '维保记录管理',
			roles: ['f8c2fb322baed0909499810db3b8ee68'],
		},
		children: [
			{
				path: 'index',
				name: 'MaintainManage',
				component: () => import('@/views/MaintainManage/index.vue'),
				meta: {
					noCache: false,
					title: '维保记录管理',
					icon: 'icon-weixiufuwu',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'maintaindetail',
				name: 'MaintainDetailManage',
				hidden: true,
				component: () => import('@/views/MaintainManage/detail.vue'),
				meta: {
					noCache: false,
					title: '维保记录详情',
					icon: 'icon-dingdan',
					activeMenu: '/maintainmanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/usermanage',
		redirect: '/usermanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '用户管理',
			roles: ['a22f30d30839005c3dba903289215bd9'],
		},
		children: [
			{
				path: 'index',
				name: 'UserManage',
				component: () => import('@/views/UserManage/index.vue'),
				meta: {
					noCache: false,
					title: '用户管理',
					icon: 'icon-haoyou',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'useredit',
				name: 'UserEditManage',
				hidden: true,
				component: () => import('@/views/UserManage/edit.vue'),
				meta: {
					noCache: true,
					title: '用户编辑',
					icon: 'icon-bianji',
					activeMenu: '/usermanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/placemanage',
		redirect: '/placemanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '地点管理',
			roles: ['1623fdea890bef0bb69a0398fb42edc8'],
		},
		children: [
			{
				path: 'index',
				name: 'PlaceManage',
				component: () => import('@/views/PlaceManage/index.vue'),
				meta: {
					noCache: false,
					title: '地点管理',
					icon: 'icon-zhiyuandidian3',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'placeedit',
				name: 'PlaceEditManage',
				hidden: true,
				component: () => import('@/views/PlaceManage/edit.vue'),
				meta: {
					noCache: true,
					title: '地点编辑',
					icon: 'icon-bianji',
					activeMenu: '/placemanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/departmentmanage',
		redirect: '/departmentmanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '部门管理',
			roles: ['c57bc465f25aad9c6330f03cf20307e3'],
		},
		children: [
			{
				path: 'index',
				name: 'DepartmentManage',
				component: () => import('@/views/DepartmentManage/index.vue'),
				meta: {
					noCache: false,
					title: '部门管理',
					icon: 'icon-zuzhibumen',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'departmentdetail',
				name: 'DepartmentEditManage',
				hidden: true,
				component: () => import('@/views/DepartmentManage/edit.vue'),
				meta: {
					noCache: true,
					title: '部门编辑',
					icon: 'icon-bianji',
					activeMenu: '/departmentmanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/projectmanage',
		redirect: '/projectmanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '项目管理',
			roles: ['b0ecdfd61ed1e33c8302739764e7bb52'],
		},
		children: [
			{
				path: 'index',
				name: 'ProjectManage',
				component: () => import('@/views/ProjectManage/index.vue'),
				meta: {
					noCache: false,
					title: '项目管理',
					icon: 'icon-icon-project',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'projectedit',
				name: 'ProjectEditManage',
				hidden: true,
				component: () => import('@/views/ProjectManage/edit.vue'),
				meta: {
					noCache: true,
					title: '项目编辑',
					icon: 'icon-bianji',
					activeMenu: '/projectmanage/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/announcement',
		redirect: '/announcement/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '公告管理',
			roles: ['c03510932da8efe23f90c260d0dc085c'],
		},
		children: [
			{
				path: 'index',
				name: 'Announcement',
				component: () => import('@/views/AnnouncementManage/index.vue'),
				meta: {
					noCache: false,
					title: '公告管理',
					icon: 'icon-gonggao',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'announcementedit',
				name: 'AnnouncementEditManage',
				hidden: true,
				component: () => import('@/views/AnnouncementManage/edit.vue'),
				meta: {
					noCache: true,
					title: '公告编辑',
					icon: 'icon-bianji',
					activeMenu: '/announcement/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
			{
				path: 'announcementdetail',
				name: 'AnnouncementDetailManage',
				hidden: true,
				component: () => import('@/views/AnnouncementManage/detail.vue'),
				meta: {
					noCache: true,
					title: '公告详情',
					icon: 'icon-bianji',
					activeMenu: '/announcement/index',
					// roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},
	{
		path: '/exportmanage',
		redirect: '/exportmanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '导出日志列表',
			roles: ['cf0fc83f20e700a6aa13203fc9282929'],
		},
		children: [
			{
				path: 'index',
				name: 'ExportManage',
				component: () => import('@/views/ExportManage/index.vue'),
				meta: {
					noCache: false,
					title: '导出日志列表',
					icon: 'icon-liebiao',
				},
			},
		],
	},

	//匹配所有路径 使用 /:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
	{ path: '/:catchAll(.*)', redirect: '/404', hidden: true },
]

const createNewRouter = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	// history: createWebHistory(process.env.BASE_URL),
	routes: constantRoutes.concat(asyncRoutes),
})

const router = createNewRouter

export function resetRouter() {
	router.getRoutes().forEach((route) => {
		const { name } = route
		if (name) {
			router.hasRoute(name) && router.removeRoute(name)
		}
	})
}
export default router
