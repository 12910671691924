import Cookies from 'js-cookie'
import AES from '@/utils/AES'
const TokenKey = 'admin_token'

// export function getToken() {
// 	return Cookies.get(TokenKey) ? AES.decrypt(Cookies.get(TokenKey)) : ''
// }

// export function setToken(token: string) {
// 	return token
// 		? Cookies.set(TokenKey, AES.encrypt(token))
// 		: Cookies.set(TokenKey, token)
// }

// export function removeToken() {
// 	return Cookies.remove(TokenKey)
// }

export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token: string) {
	return Cookies.set(TokenKey, token)
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}

//-----------处理其他cookie信息-----------------
export const cookieKeys = {
	account: 'account', //账号
	password: 'password', //密码
	savepassword: 'savepassword', //保存密码
	lockpassword: 'lockpassword', //锁屏密码
}
export function getCookieItem(key: string) {
	const value = Cookies.get(key)
	return value ? AES.decrypt(Cookies.get(key)) : AES.decrypt(key)
}

export function setCookieItem(key: string, value: number | string | boolean) {
	return value
		? Cookies.set(key, AES.encrypt(value as string))
		: Cookies.set(key, value)
}

export function removeCookieItem(key: string) {
	return Cookies.remove(key)
}
