import { defineStore } from 'pinia'
import { constantRoutes, asyncRoutes } from '@/router/index'
import type { RouteRecordRaw } from 'vue-router'
/**
 * 使用 meta.role 确认当前用户是否拥有权限
 * @param roles
 * @param route
 */
function hasPermission(
	roles: number[] | string[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	route: RouteRecordRaw | any,
) {
	if (route.meta && route.meta.roles) {
		return roles.some((role) => route.meta.roles.includes(role))
	} else {
		return true
	}
}
/**
 * 通过递归过滤异步路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(
	routes: Array<RouteRecordRaw>,
	roles: number[] | string[],
) {
	const res: Array<RouteRecordRaw> = []
	routes.forEach((route) => {
		const tmp = { ...route }
		if (hasPermission(roles, tmp)) {
			if (tmp.children) {
				tmp.children = filterAsyncRoutes(tmp.children, roles)
			}
			res.push(tmp)
		}
	})

	return res
}
// permission 为仓库的id
export const permissionStore = defineStore('permission', {
	state: () => {
		return {
			routes: [] as Array<RouteRecordRaw>,
			addRoutes: [] as Array<RouteRecordRaw>,
		}
	},
	getters: {},
	actions: {
		generateRoutes(roles: number[] | string[]): Promise<Array<RouteRecordRaw>> {
			return new Promise((resolve) => {
				const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
				this.addRoutes = accessedRoutes
				this.routes = constantRoutes.concat(accessedRoutes)
				resolve(accessedRoutes)
			})
		},
		resizeRoute() {
			this.routes = []
		},
	},
})
