import request from '@/utils/request'
import qs from 'qs'
import type {
	CaptchaType,
	LoginParamType,
	LoginType,
	UserInfoType,
} from './data'
//获取验证码
export async function getCaptcha(): Promise<{ data: CaptchaType }> {
	return request({
		url: '/Captcha',
		method: 'GET',
	})
}

//登录
export async function getLogin(
	param: LoginParamType,
): Promise<{ data: LoginType }> {
	return request({
		url: '/Admin/UserLogin',
		method: 'post',
		data: qs.stringify(param),
	})
}
//获取路由信息
export async function getInfo(): Promise<{ data: UserInfoType }> {
	return request({
		url: '/Admin/IndexMenu',
		method: 'post',
	})
}
